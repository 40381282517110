body {
    background-color: #333436;
    color: white;
    padding: 40px;
    font-family: Sans-Serif;
    text-align: center;
    margin-top:0px;
  }
p {
    color: rgb(212, 224, 230);
    text-align: justify;
  }
h1 {
    color: burlywood;
  }
h3 {
    color: rgb(49, 172, 165);
  }
h4 {
    text-align: justify;
    color: rgb(187, 218, 105);
  }
h5 {
    text-align: justify;
  }
a {
    color: rgb(243, 245, 160);
  }
img 
  {
      margin-bottom:10px;
      margin-left:10px;
      margin-right:10px;
  }